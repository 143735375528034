<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { TournamentTheme } from '@/types/tournaments';
import { REDDY_MAIN_GROUP_LINK, REDDY_UFC_GROUP_LINK } from '@/constants/main.const';

const props = withDefaults(defineProps<{
  sportSlug?: string;
  theme?: 'main' | TournamentTheme;
}>(), {
  theme: 'main',
});

const { t } = useI18n();
</script>

<template>
  <div
    class="footer"
    :class="{
      'footer--main': props.theme === 'main',
      'footer--path-of-sage': props.theme === TournamentTheme.PathOfSage,
      'footer--football-elements': props.theme === TournamentTheme.FootballElements,
      'footer--ufc': props.theme === TournamentTheme.UFC,
    }"
  >
    <p class="container footer__text text-16">
      {{ t('Обсуждайте прогнозы в наших группах:')}}

      <a
        class="footer__link text-16"
        :class="{
          'footer__link--main': props.theme === 'main',
          'footer__link--path-of-sage': props.theme === TournamentTheme.PathOfSage,
          'footer__link--ufc': props.theme === TournamentTheme.UFC,
        }"
        :href="REDDY_MAIN_GROUP_LINK"
        target="_blank"
      >
        ReddyFootball
      </a>

      <span> | </span>

      <a
        class="footer__link text-16"
        :class="{
          'footer__link--main': props.theme === 'main',
          'footer__link--path-of-sage': props.theme === TournamentTheme.PathOfSage,
          'footer__link--ufc': props.theme === TournamentTheme.UFC,
        }"
        :href="REDDY_UFC_GROUP_LINK"
        target="_blank"
      >
        ReddyUFC
      </a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  width: 100%;
  padding: 12px 0;

  &--main {
    background: $clear-1;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }

  &--path-of-sage {
    border-top: 2px solid $yellow-120;
    background-color: $lilac-900;
  }

  &--football-elements {
    border-top: 2px solid $yellow-100-fe;
    background-color: $lilac-900;

    .footer__link {
      color: $clear-60;

      @include hover-focus {
        color: $clear-80;
      }

      @include active {
        color: $clear-100;
      }
    }
  }

  &--ufc {
    border-top: 1px solid $ufc-primary-100;
    background-color: $ufc-dark-200;
  }
}

.footer__text {
  text-align: center;
  word-break: break-word;
  letter-spacing: 0.01em;
}

.footer__link {
  margin-top: 8px;
  display: inline-block;
  text-decoration: underline;
  color: $clear-100;

  @include md {
    margin-top: 0;
    display: inline-block;
  }

  &--main {
    // empty
  }

  &--path-of-sage {
    @include md {
      color: $clear-60;
    }
  }

  &--ufc {
    color: $ufc-primary-100;
  }
}
</style>
