<script setup lang="ts">
import { watch } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import BaseToastWrapper from '@/components/Base/ToastWrapper/BaseToastWrapper.vue';
import { LocalesEnum } from '@/types/locales';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { useDate } from '@/composables/useDate';
import { localStorageHelper } from '@/helpers/localStorage';

const route = useRoute();
const { locale } = useI18n();
const authStore = useAuthStore();
const { setLocale } = useDate();
const storage = localStorageHelper();

watch(() => authStore.user, () => {
  if (authStore.user?.lang && Object.values(LocalesEnum).includes(authStore.user.lang as LocalesEnum)) {
    if (authStore.user.lang !== locale.value) {
      locale.value = authStore.user.lang;
    }

    setLocale(authStore.user.lang);
    storage.setItemInLocalStorage(import.meta.env.VITE_STORAGE_KEY_LOCALE, authStore.user.lang);
  }
});
</script>

<template>
  <component :is="route.meta.layout || 'div'">
    <RouterView />
    <BaseToastWrapper />
  </component>
</template>
