export default {
  tournaments: 'Турниры',
  hallOfFame: 'Зал славы',
  faq: 'FAQ',
  predictions: 'Прогнозы',
  ratings: 'Рейтинги',
  stats: 'Статистика',
  rules: 'Правила',
  watch: 'Смотреть',
  titlePredictions: 'Прогнозы',
  player: 'Игрок',
  team: 'Команда',
  goals: 'Голы',
  saveButtonText: 'Сохранить',
  totalPoints: 'Всего баллов',
  roundPoints: 'Баллов за тур',
  modalGroupCta: 'Пожалуйста, определитесь с группой!',
  modalInvalidExactScore: 'Пожалуйста, заполни второе поле матча, чтобы сохранить прогноз.',
  modalChooseTitle: 'Пожалуйста, выберете 8 команд!',
  modalChooseMostProductiveTeam: 'Пожалуйста, выберете команду!',
  okText: 'Хорошо',
  rightPredictionText: 'Ты угадал!',
  rightPredictionTextExcellent: 'Отличный результат!',
  rightPredictionTextGood: 'Неплохо! Ты молодец!',
  rightPredictionTextNotEnough: 'Повезет в другой раз!',
  rightPredictionPoints: 'балла',
  rightPredictionMaxPoints: 'баллoв',
  loosPredictionText: 'Повезет в другой раз',
  loosPredictionPoints: 'баллов',
  points: 'баллa',
  final: 'Финал',
  tournamentsFeedTitle: 'Доступные турниры',
  baseMessageInitialText: 'Текст сообщения',
  statsPreviewText: 'Посмотреть подробную статистику можно по',
  byLink: 'ссылке',
  goHome: 'На главную',
  reload: 'Перезагрузить',
  pageNotFoundText: 'Страница не найдена',
  participants: 'Участники',
  allParticipants: 'Все участники',
  tournamentLoser: 'Лох турнира',
  allStagesRating: 'Общий рейтинг',
  apply: 'Применить',
  rounds: 'Стадии турнира',
  yes: 'Да',
  no: 'Нет',
  super: 'Супер!',
  divideToGroupButtonText: 'Определиться',
  ctaDividerText: 'Узнай свою тематическую группу и оставляй прогнозы!',
  feCtaDividerText: 'Время узнать твою стихию и начать ставить прогнозы!',
  videoErrorText: 'Ваш браузер не поддерживает встроенные видео.(',
  randomizerText: 'Да поможет тебе великий рандом!',
  successDivideInGroupText: 'Ты определен в группу!',
  feSuccessDivideInGroupText: 'Твоя стихия -',
  timerToDivideInTeamText: 'Определение по командам произойдет через',
  waitToDivideInTeamText: 'Дождись распределения в команду',
  ctaDivideTextPart1: 'Готов пройти путь легендарных наставников?',
  ctaDivideTextPart2: 'Определи свой тренерский стиль!',
  ctaDivideTextPart1Fe: 'Кто ты, воин?',
  ctaDivideTextPart2Fe: 'Определи, какой футбольный элемент горит в твоём сердце и вперед к победам!',
  rankingInTeam: 'Твое место в команде',
  participantsInTeam: 'Участников в команде',
  teamRankingInTheGroup: 'Место команды в группе',
  placeGroup: 'Твое место в группе',
  teamChat: 'Чат с командой',
  predictionTimerText: 'До начала первого матча осталось:',
  timerToStartTournamentText: 'До начала турнира осталось:',
  defaultErrorText: 'Что-то пошло не так...',
  change: 'Изменить',
  predictionTimerOut: 'Время вышло. Ты больше не можешь сделать/изменить прогнозы.',
  predictionEmptyTimer: 'Прогнозы станут доступны, когда появится дата начала турнира.',
  groupStageTimerText: 'До начала групповой стадии:',
  groupStageFinishTimerText: 'До конца групповой стадии:',
  playOffStageTimerText: 'До начала первого матча стадии {name} осталось:',
  playOffFinalTimerText: 'До начала финала осталось:',
  sages: 'мудрецы',
  elements: 'Стихии',
  signIn: 'Войти',
  requiredField: 'Обязательное поле',
  wrongOtp: 'Код введен некорректно. Проверьте код в Reddy и введите повторно.',
  emptyDataMessage: 'По заданным параметрам игроков не найдено',
  ratingsNotAvailableYet: 'Рейтинги станут доступны позднее',
  successClipboard: 'Скопированно в буфер обмена',
  errorClipboard: 'Не удалось скопировать в буфер обмена',
  'Зал славы пока не сформирован. Дождитесь окончания серии номерных турниров.': 'Зал славы пока не сформирован. Дождитесь окончания серии номерных турниров.',
  'Ошибки валидации': 'Ошибки валидации',
  'До начала первого боя осталось:': 'До начала первого боя осталось:',
  'Событие отменено': 'Отменен',
  'Обсуждайте прогнозы в наших группах:': 'Обсуждайте прогнозы в наших группах:',
  rankingPageTitle: 'Рейтинги',
  topPageTitle: 'Победители Турнира',
};
