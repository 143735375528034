import { createI18n } from 'vue-i18n';
import { localStorageHelper } from '@/helpers/localStorage';
import en from '@/locales/en';
import ru from '@/locales/ru';
import { LocalesEnum } from '@/types/locales';

export const getLocale = (): LocalesEnum => {
  const storage = localStorageHelper();
  const storageLanguage = storage.getItemFromLocalStorage(import.meta.env.VITE_STORAGE_KEY_LOCALE);

  if (storageLanguage && Object.values(LocalesEnum).includes(storageLanguage)) {
    return storageLanguage;
  }

  return import.meta.env.VITE_DEFAULT_LOCALE;
};

const customRule = (choice: number, choicesLength: number): number => {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};

export default createI18n({
  locale: getLocale(),
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  pluralRules: {
    ru: customRule,
  },
  messages: {
    en,
    ru,
  },
});
